import {useState} from "react";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import { RxDashboard, RxEyeOpen, RxPencil1, RxPerson, RxPlus } from 'react-icons/rx';
import { usePublicChannelStore,PublicChannelStore,PublicChannelStoreContext } from "./store/PublicChannelStore";
// import Filter from "./UserFilter/userfilter";
import Pagination from "react-js-pagination";
import { useEffect } from "react";
import Moment from "react-moment";

const GroupManagement = () => {
   
    const store = new PublicChannelStore({
      isloading:true,
      editloading:false,
      pagginationcount:1
    });
    return(
      <PublicChannelStoreContext.Provider value={store}>
        <Screen/>
      </PublicChannelStoreContext.Provider>
    )
  }
  
  export default GroupManagement;

  const Screen = observer(()=>{
    const [roomId, setRoomId] = useState("");
    const [roomname, setroomname] = useState("");
    const [trendingstatus, setTrendingstatus] = useState(false);
    const [isHide, setIsHide] = useState(null);
    const [channelId, setChannelId] = useState("");
    const {
      users,  
      loaduser, 
      nolist,
      filterVisibility,
      activePage,
      perPage, 
      Count,
      indexPage,
      filtertrue,
      getFilterUsers,
      loadTableData,
      getUsers,
      setIndexPage,
      setActivePage,
      statusChange,
      deleteGroup,
      removeTrendingGroup,
      getstatusid,
      nofilterwithpagination,
      NofilterwithPagination
    } = usePublicChannelStore();

   

    const handlePageChange = (pageNumber) => {
      NofilterwithPagination(true)
      const element = document.getElementById("tablescroll");
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
        if(filtertrue){
          
          getFilterUsers(pageNumber)
          setIndexPage(pageNumber);
          setActivePage(pageNumber);
        }else{
          if (filterVisibility === true) {
            getUsers(pageNumber);
            setActivePage(pageNumber);
            setIndexPage(pageNumber);
          } else {
            setActivePage(pageNumber);
            setIndexPage(pageNumber);
          }
        }
      };
//   useEffect(() => {
//   //  loadTableData(1);
//   //  getUsers();
//  }, []);

   // console.log("users in grpmngmt", users);
    return(
      <>
      <div className='flex justify-between'>
      <div className=" mb-4">
          <p className=" text-2xl font-semibold text-moredarkgreen">Public Channel Management</p>
          {/* <p className=" opacity-80 text-sm">Home / User</p> */}
        </div>
        {/* <div>
          <Link to="./adduser" className="btn btn-sm bg-[#000] text-green text-sm hover:bg-[#000]"><MdAdd/>Add User</Link>
        </div> */}
       </div>
       {/* <Filter/> */}
       <div className="card bg-base-100 shadow-md p-6 mt-8">
        <div className="overflow-x-auto w-full">
        <table className="table table-zebra w-full">
        
            <thead>           
            <tr>
                <th>S.no</th>
                <th>Display Image</th>
                <th>Channel Name</th>
                <th style={{ whiteSpace: "normal" }}>Owner</th>
                <th>Members Count</th>
                <th>Created On</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody  id="tablescroll" className="text-sm relative">
            {loaduser && 
              (<>
              <tr>
                <td colSpan={9} className="animate-pulse py-6"></td>
              </tr>
              <tr>
                <td colSpan={9} className="animate-pulse py-6"></td>
              </tr>
              <tr>
                <td colSpan={9} className="animate-pulse py-6"></td>
              </tr>
              <tr>
                <td colSpan={9} className="animate-pulse py-6"></td>
              </tr>
              <tr>
                <td colSpan={9} className="animate-pulse py-6"></td>
              </tr>
              </>)
              }
            
            {!loaduser && !nolist && users && users?.map((data,index)=> (
              <tr key={index}>
              <td>  {perPage * (indexPage - 1) + (index + 1)}</td>
              <td className="font-bold capitalize ">{data?.image ? <img className="w-[50px] h-[50px] rounded-full bg-gray-200"  src={data?.image} alt="Group" /> : "N/A"}</td>
              <td className="font-bold capitalize">{(data?.name??"N/A")}</td>
              <td className=" capitalize"><div className="whitespace-normal font-bold">{data?.owner?.name || "-"}</div><p className="capitalize text-xs">+{data?.owner?.phone_number}</p></td>
              <td className="font-bold capitalize">{data?.membersCount || "-"}</td>
              <td className="capitalize"><Moment format="MM/DD/YYYY">{data?.createdAt}</Moment></td>
              <td>
              <div className="flex items-center space-x-4">
                  {data?.isHide == true ?
                    <label
                    title="Change Status"
                    htmlFor="my-modal-5"
                    className={`btn capitalize ${ data?.isHide == 1 && "bg-warning"} hover:bg-warning  text-[#000] border-hidden  btn-xs`}
                    onClick={(e) => {
                      setRoomId(data?._id);
                      setIsHide(data?.isHide);
                      // getNewstatus(data?.isActive);
                    }}
                  >
                    {data?.isHide == true && "Unhide"}
                  </label>
                  :
                  <label
                  title="Change Status"
                  htmlFor="my-modal-5"
                  className={`btn capitalize ${!data?.isHide ? "bg-green hover:bg-green" : "bg-warning hover:bg-warning"}  text-[#000] border-hidden mr-4 btn-xs`}
                  onClick={(e) => {
                    setRoomId(data?._id);
                    setIsHide(data?.isHide);
                    // getNewstatus(data?.isActive);
                  }}
                >
                  {data?.isHide == false && "Hide"}
                </label>
                  }
                 {/*<button className={`btn capitalize bg-[#ff0000] hover:bg-[#ff0000]  text-[#fff] border-hidden  btn-xs ml-2`}>Delete</button>*/}
                 <label
                  title="Delete Channel"
                  htmlFor="delete-modal"
                  className={`btn capitalize bg-[#ff0000] hover:bg-[#ff0000] text-[#fff] border-hidden ml-2  btn-xs`}
                  onClick={(e) => {
                    setRoomId(data?._id);
                    setroomname(data?.name)
                    // getNewstatus(data?.isActive);
                  }}
                >
                  Delete
                </label>
                
                <label
                title="Remove Trending"
                htmlFor="remove-trending"
                className={`btn capitalize ${data?.shouldTrending  ? "bg-[#ff0000] hover:bg-[#ff0000] text-[#fff]" : "bg-warning hover:bg-warning text-[#000]" }  border-hidden ml-2  btn-xs`}
                onClick={(e) => {
                  setRoomId(data?._id);
                  setTrendingstatus(!data?.shouldTrending);
                  // getNewstatus(data?.isActive);
                }}
              >
                {data?.shouldTrending ? "Remove Trending" : "Add Trending"}
              </label>
                
                   </div>
            </td>                
              </tr>
            ))}

            {nolist && 
              <tr>
                <td colSpan={9} className="my-10 text-center">
                  <RxPerson className=" text-[#dedede] text-8xl mx-auto" />
                  <p className=" font-semibold text-lg">No Channel Found</p>
                </td>
              </tr>
              }        
            
            </tbody>
        </table>
        
        <Pagination
          activePage={activePage}
          containerClassName={'pagination'}
          itemsCountPerPage={Number(perPage)}
          totalItemsCount={Number(Count)}
          pageRangeDisplayed={10}
          onChange={handlePageChange}
        />

        {/* status change model start */}
        <input type="checkbox" id="my-modal-5" className="modal-toggle" />
          <div className="modal">
            <div className="modal-box relative">
              <label
                htmlFor="my-modal-5"
                className="btn btn-sm btn-circle absolute right-2 top-2"
              >
                ✕
              </label>
              <h3 className="text-lg font-bold text-center">
                Are you sure you want to change Status?
              </h3>
              <div className="modal-action justify-center">
                <label
                  htmlFor="my-modal-5"
                  className="btn btn-md bg-darkgreen text-[#fff] text-sm hover:text-darkgreen hover:bg-lightgreen  ml-4"
                  onClick={() => {statusChange(roomId, !isHide)}}
                >
                  Yes
                </label>
                <label htmlFor="my-modal-5" className="btn btn-md bg-lightgreen text-darkgreen text-sm hover:bg-darkgreen hover:text-[#fff]  ">
                  No
                </label>
              </div>
            </div>
          </div>
          {/* status change model end */}

          {/* group delete model start */}
        <input type="checkbox" id="delete-modal" className="modal-toggle" />
          <div className="modal">
            <div className="modal-box relative">
              <label
                htmlFor="delete-modal"
                className="btn btn-sm btn-circle absolute right-2 top-2"
              >
                ✕
              </label>
              <h3 className="text-lg font-bold text-center">
                Are you sure you want to delete this channel?
              </h3>
              <div className="modal-action justify-center">
                <label
                  htmlFor="delete-modal"
                  className="btn btn-md bg-darkgreen text-[#fff] text-sm hover:text-darkgreen hover:bg-lightgreen  ml-4"
                  onClick={() => {deleteGroup(roomId,roomname)}}
                >
                  Yes
                </label>
                <label htmlFor="delete-modal" className="btn btn-md bg-lightgreen text-darkgreen text-sm hover:bg-darkgreen hover:text-[#fff]  ">
                  No
                </label>
              </div>
            </div>
          </div>
          {/* group delete model end */}

             {/* group delete model start */}
        <input type="checkbox" id="remove-trending" className="modal-toggle" />
          <div className="modal">
            <div className="modal-box relative">
              <label
                htmlFor="remove-trending"
                className="btn btn-sm btn-circle absolute right-2 top-2"
              >
                ✕
              </label>
              <h3 className="text-lg font-bold text-center">
                Are you sure you want to remove this channel from trending?
              </h3>
              <div className="modal-action justify-center">
                <label
                  htmlFor="remove-trending"
                  className="btn btn-md bg-darkgreen text-[#fff] text-sm hover:text-darkgreen hover:bg-lightgreen  ml-4"
                  onClick={() => {removeTrendingGroup(roomId,trendingstatus)}}
                >
                  Yes
                </label>
                <label htmlFor="remove-trending" className="btn btn-md bg-lightgreen text-darkgreen text-sm hover:bg-darkgreen hover:text-[#fff]  ">
                  No
                </label>
              </div>
            </div>
          </div>
          {/* group delete model end */}
        </div>
    </div>
       </>
    )
  })